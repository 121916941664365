var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vxe-modal",
    {
      staticClass: "device-alarm-modal",
      attrs: {
        width: "60%",
        height: "80%",
        title: "位置状态信息",
        "show-zoom": "",
        resize: "",
        "lock-view": false,
        mask: false,
        "esc-closable": true,
        position: _vm.position,
        transfer: "",
      },
      on: { close: _vm.handleCloaseCamera, hide: _vm.handleCloaseCamera },
      model: {
        value: _vm.statusVisible,
        callback: function ($$v) {
          _vm.statusVisible = $$v
        },
        expression: "statusVisible",
      },
    },
    [
      _vm.info.deviceId
        ? _c(
            "a-descriptions",
            { attrs: { bordered: "" } },
            [
              _c("a-descriptions-item", { attrs: { label: "ACC" } }, [
                _vm._v(" " + _vm._s(_vm.info.acc == 0 ? "关" : "开") + " "),
              ]),
              _c(
                "a-descriptions-item",
                { attrs: { label: "是否使用北斗卫星进行定位" } },
                [_vm._v(" " + _vm._s(_vm.info.beidou == 0 ? "否" : "是") + " ")]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: "紧急刹车系统采集的前撞预警" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.info.brakeSystemWarning == 0 ? "未发生" : "发生"
                      ) +
                      " "
                  ),
                ]
              ),
              _c("a-descriptions-item", { attrs: { label: "装载状态" } }, [
                _vm._v(" " + _vm._s(_vm.cargoMap[_vm.info.cargo]) + " "),
              ]),
              _c("a-descriptions-item", { attrs: { label: "车辆电路" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.info.circuitBreak == 0 ? "正常" : "断开") +
                    " "
                ),
              ]),
              _c("a-descriptions-item", { attrs: { label: "上班打卡状态" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.info.clockIned == 0 ? "未打卡状态" : "已打卡状态"
                    ) +
                    " "
                ),
              ]),
              _c("a-descriptions-item", { attrs: { label: "下班打卡状态" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.info.clockOuted == 0 ? "未打卡状态" : "已打卡状态"
                    ) +
                    " "
                ),
              ]),
              _c("a-descriptions-item", { attrs: { label: "设备时间" } }, [
                _vm._v(" " + _vm._s(_vm.info.deviceTime) + " "),
              ]),
              _c("a-descriptions-item", { attrs: { label: "外电插入状态" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.info.extIncharge == 0 ? "未接充电器" : "已接充电器"
                    ) +
                    " "
                ),
              ]),
              _c(
                "a-descriptions-item",
                { attrs: { label: "是否使用Galileo卫星进行定位" } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.info.galileo == 0 ? "是" : "否") + " "
                  ),
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: "是否使用GLONASS卫星进行定位" } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.info.glonass == 0 ? "是" : "否") + " "
                  ),
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: "是否使用GPS卫星进行定位" } },
                [_vm._v(" " + _vm._s(_vm.info.gps == 0 ? "是" : "否") + " ")]
              ),
              _c("a-descriptions-item", { attrs: { label: "车道偏移预警" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.info.laneDepartureWarning == 0 ? "未发生" : "发生"
                    ) +
                    " "
                ),
              ]),
              _c("a-descriptions-item", { attrs: { label: "车门是否解锁" } }, [
                _vm._v(" " + _vm._s(_vm.info.locking == 0 ? "是" : "否") + " "),
              ]),
              _c("a-descriptions-item", { attrs: { label: "车辆油路" } }, [
                _vm._v(
                  " " + _vm._s(_vm.info.oilBreak == 0 ? "正常" : "断开") + " "
                ),
              ]),
              _c("a-descriptions-item", { attrs: { label: "前门" } }, [
                _vm._v(
                  " " + _vm._s(_vm.info.opening1 == 0 ? "关" : "开") + " "
                ),
              ]),
              _c("a-descriptions-item", { attrs: { label: "中门" } }, [
                _vm._v(
                  " " + _vm._s(_vm.info.opening2 == 0 ? "关" : "开") + " "
                ),
              ]),
              _c("a-descriptions-item", { attrs: { label: "后门" } }, [
                _vm._v(
                  " " + _vm._s(_vm.info.opening3 == 0 ? "关" : "开") + " "
                ),
              ]),
              _c("a-descriptions-item", { attrs: { label: "驾驶席门" } }, [
                _vm._v(" " + _vm._s(_vm.info.opening4) + " "),
              ]),
              _c("a-descriptions-item", { attrs: { label: "自定义门" } }, [
                _vm._v(
                  " " + _vm._s(_vm.info.opening5 == 0 ? "关" : "开") + " "
                ),
              ]),
              _c("a-descriptions-item", { attrs: { label: "定位" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.info.positioning == 0 ? "未定位" : "定位") +
                    " "
                ),
              ]),
              _c("a-descriptions-item", { attrs: { label: "纬度" } }, [
                _vm._v(
                  " " + _vm._s(_vm.info.south == 0 ? "北纬" : "南纬") + " "
                ),
              ]),
              _c("a-descriptions-item", { attrs: { label: "经度" } }, [
                _vm._v(
                  " " + _vm._s(_vm.info.west == 0 ? "东经" : "西经") + " "
                ),
              ]),
              _c("a-descriptions-item", { attrs: { label: "运营状态" } }, [
                _vm._v(
                  " " + _vm._s(_vm.info.suspended == 0 ? "正常" : "停运") + " "
                ),
              ]),
              _c("a-descriptions-item", { attrs: { label: "车辆行驶状态" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.info.vehicleStatus == 0 ? "停止" : "行驶") +
                    " "
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.showNodata
        ? _c("div", { staticClass: "no-data-class" }, [
            _vm._v(" 未获取到事件报警信息 "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }