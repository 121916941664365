<!--
 * @Auth: linjituan
 * @Date: 2021-11-24 09:35:02
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2024-04-24 11:53:33
 * @LastEditTime: 2024-03-05 19:45:01
-->
<template>
  <vxe-modal
    v-model="statusVisible"
    width="60%"
    height="80%"
    class="device-alarm-modal"
    title="位置状态信息"
    @close="handleCloaseCamera"
    @hide="handleCloaseCamera"
    show-zoom
    resize
    :lock-view="false"
    :mask="false"
    :esc-closable="true"
    :position="position"
    transfer
  >
    <a-descriptions bordered v-if="info.deviceId">
      <a-descriptions-item label="ACC">
        {{ info.acc == 0 ? '关' : '开' }}
      </a-descriptions-item>
      <a-descriptions-item label="是否使用北斗卫星进行定位">
        {{ info.beidou == 0 ? '否' : '是' }}
      </a-descriptions-item>
      <a-descriptions-item label="紧急刹车系统采集的前撞预警">
        {{ info.brakeSystemWarning == 0 ? '未发生' : '发生' }}
      </a-descriptions-item>
      <a-descriptions-item label="装载状态">
        {{ cargoMap[info.cargo] }}
      </a-descriptions-item>
      <a-descriptions-item label="车辆电路">
        {{ info.circuitBreak == 0 ? '正常' : '断开' }}
      </a-descriptions-item>
      <a-descriptions-item label="上班打卡状态">
        {{ info.clockIned == 0 ? '未打卡状态' : '已打卡状态' }}
      </a-descriptions-item>
      <a-descriptions-item label="下班打卡状态">
        {{ info.clockOuted == 0 ? '未打卡状态' : '已打卡状态' }}
      </a-descriptions-item>
      <a-descriptions-item label="设备时间">
        {{ info.deviceTime }}
      </a-descriptions-item>
      <a-descriptions-item label="外电插入状态">
        {{ info.extIncharge == 0 ? '未接充电器' : '已接充电器' }}
      </a-descriptions-item>
      <a-descriptions-item label="是否使用Galileo卫星进行定位">
        {{ info.galileo == 0 ? '是' : '否' }}
      </a-descriptions-item>
      <a-descriptions-item label="是否使用GLONASS卫星进行定位">
        {{ info.glonass == 0 ? '是' : '否' }}
      </a-descriptions-item>
      <a-descriptions-item label="是否使用GPS卫星进行定位">
        {{ info.gps == 0 ? '是' : '否' }}
      </a-descriptions-item>
      <a-descriptions-item label="车道偏移预警">
        {{ info.laneDepartureWarning == 0 ? '未发生' : '发生' }}
      </a-descriptions-item>
      <a-descriptions-item label="车门是否解锁">
        {{ info.locking == 0 ? '是' : '否' }}
      </a-descriptions-item>
      <a-descriptions-item label="车辆油路">
        {{ info.oilBreak == 0 ? '正常' : '断开' }}
      </a-descriptions-item>
      <a-descriptions-item label="前门">
        {{ info.opening1 == 0 ? '关' : '开' }}
      </a-descriptions-item>
      <a-descriptions-item label="中门">
        {{ info.opening2 == 0 ? '关' : '开' }}
      </a-descriptions-item>
      <a-descriptions-item label="后门">
        {{ info.opening3 == 0 ? '关' : '开' }}
      </a-descriptions-item>
      <a-descriptions-item label="驾驶席门">
        {{ info.opening4 }}
      </a-descriptions-item>
      <a-descriptions-item label="自定义门">
        {{ info.opening5 == 0 ? '关' : '开' }}
      </a-descriptions-item>
      <a-descriptions-item label="定位">
        {{ info.positioning == 0 ? '未定位' : '定位' }}
      </a-descriptions-item>
      <a-descriptions-item label="纬度">
        {{ info.south == 0 ? '北纬' : '南纬' }}
      </a-descriptions-item>
      <a-descriptions-item label="经度">
        {{ info.west == 0 ? '东经' : '西经' }}
      </a-descriptions-item>
      <a-descriptions-item label="运营状态">
        {{ info.suspended == 0 ? '正常' : '停运' }}
      </a-descriptions-item>
      <a-descriptions-item label="车辆行驶状态">
        {{ info.vehicleStatus == 0 ? '停止' : '行驶' }}
      </a-descriptions-item>
    </a-descriptions>
    <div class="no-data-class" v-if="showNodata">
      未获取到事件报警信息
    </div>
  </vxe-modal>
</template>

<script>
import { statusDeviceLocation } from '@/api/jt808/deviceLocation'
import { Descriptions } from 'ant-design-vue'
export default {
  components: {
    ADescriptions: Descriptions,
    ADescriptionsItem: Descriptions.Item
  },
  data() {
    return {
      info: {},
      statusVisible: false,
      showNodata: false,
      cargoMap: {
        0: '空车',
        1: '半载',
        2: '保留',
        3: '满载'
      },
      position: {}
    }
  },
  methods: {
    open(record, left) {
      this.info = {}
      this.showNodata = false
      this.alarmEvent(record)
      this.statusVisible = true
      if (left) {
        this.$nextTick(() => {
          this.position.left = left
        })
      }
    },
    // 事件警报
    alarmEvent(record) {
      statusDeviceLocation({
        deviceId: record.deviceId, // 'test123456', // record.deviceId,
        deviceTime: record.deviceTime // '2021-11-19 07:23:59' // record.deviceTime
      }).then(response => {
        if (response.data) {
          this.info = response.data
        } else {
          this.showNodata = true
        }
      })
    },
    handleCloaseCamera() {
      this.statusVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.device-alarm-modal {
  .ant-descriptions {
    height: auto;
  }
  .no-data-class {
    text-align: center;
    padding: 20px 0;
  }
}
</style>
